import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from 'react-i18next';

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormInput from "../../controls/input";
import { yupResolver } from "@hookform/resolvers";

import { useUser } from "../../../store";
import { useSnackbar } from 'notistack';
import { userService } from "../../../httpServices/services";
import { makeStyles, Typography } from "@material-ui/core";
import { useTextBox } from "../../../styles";
import { useHistory } from 'react-router-dom';
import changePasswordFirstTimeSchema from "../../../validations/changePasswordFirstTimeSchema";

const ChangePasswordFirstTime = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user, setUser } = useUser();
  const methods = useForm({
    resolver: yupResolver(changePasswordFirstTimeSchema(useTranslation())),
  });
  const { handleSubmit, errors } = methods;
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    title: {
      paddingBottom: '20px',
    },
    send: {
      marginTop: '20px'
    },
  }));
  const onSubmit = (data) => {
    const payload = {
      password: data?.password
    };



    let response = null;
    response = userService.firstChangePassword(payload)


    response.then(res => {
      if (res.data !== undefined) {
        enqueueSnackbar(t('password_changed'), { variant: 'success' });
        // TODO
        //REFRESH PAGE
        let updatedUser = user;
        updatedUser.mustChangePassword = false;
        setUser(updatedUser)
        history.push("/login");
      } else {
        enqueueSnackbar(t('server_error'), { variant: 'error' });
      }
    })
      .catch(err => {
        enqueueSnackbar(t('server_error'), { variant: 'error' });
      })
  };

  const textBoxClasses = useTextBox();
  const classes = useStyles();

  return (
    <FormProvider {...methods}>
      <form>
        <Grid container direction="column" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} className={classes.title}>
            <Typography variant="h1">{t('changePassWord')}</Typography>
          </Grid>
          <Grid item xs={12} className={textBoxClasses.almostFull}>
            <FormInput
              name="password"
              type='password'
              label={t('newPassword')}
              required={true}
              errorobj={errors}
            />
          </Grid>
          <Grid item className={textBoxClasses.almostFull}>
            <FormInput
              name="repassword"
              type='password'
              label={t('reNewPassword')}
              required={true}
              errorobj={errors}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              className={classes.send}
            >
              {t('change')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default ChangePasswordFirstTime;