import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import paths from './paths';
import { useUser } from '../../store';
import ChangePasswordViewPaper from '../../components/pages/ChangePasswordViewPaper/ChangePasswordViewPaper';
import loggedIn from '../../services/loggedIn';
import CarrierAcceptTerms from '../../components/pages/CarrierAcceptTerms/CarrierAcceptTerms';

const CarrierRoute = (props) => {
  const { user } = useUser();

  if (loggedIn(user) && user.role === 'CARRIER') {
    if (user.mustChangePassword === true) {
      return (
        <ChangePasswordViewPaper />
      );
    }else if (user.user?.terms === false) {
      return (
        <CarrierAcceptTerms />
      )
    } else {
      return (
        <Route path={props.path} exact={props.exact} component={props.component} />
      );
    }
  } else {
    return <Redirect to={paths.login} />;
  }
};

export default CarrierRoute;
