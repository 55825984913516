import * as yup from 'yup';

const completeContractValidations = (props) => {
  const { t } = props;
  return yup.object().shape({
    signedBy: yup.string().required(t('signedByRequired')),
    signedByCardID: yup.string().required(t('signedByCardIDRequired')),
  });
};
export default completeContractValidations;
