import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ENTranslation from '../consts/translations/ENTranslations.json';
import ESTranslation from '../consts/translations/ESTranslations.json';

// the translations
const resources = {
  es: {
    translation: ESTranslation,
  },
  en: {
    translation: ENTranslation,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'es', // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
