import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { CircularProgress, Grid } from '@material-ui/core';
import paths from './paths';
import { useUser } from '../../store';
import CompleteContract from '../../components/pages/CompleteContract/CompleteContract';
import { clientService } from '../../httpServices/services';
import ChangePasswordViewPaper from '../../components/pages/ChangePasswordViewPaper/ChangePasswordViewPaper';
import ClientAcceptTerms from '../../components/pages/ClientAcceptTerms/ClientAcceptTerms';
import loggedIn from '../../services/loggedIn';

const ClientRoute = (props) => {
  const { user } = useUser();
  const [pendigContracts, setPendigContracts] = useState();
  const [loading, setLoading] = useState(false);

  const hasPendinContracts = () => {
    setLoading(true);
    clientService.hasPendingContract()
      .then((res) => {
        if (res.data !== undefined && res.data.status === 'PENDING') {
          setPendigContracts(res.data);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    hasPendinContracts();
  }, []);

  if (loggedIn(user) && user.role === 'CLIENT') {
    if (user.mustChangePassword === true) {
      return (
        <ChangePasswordViewPaper />
      );
    } if (user.user?.terms === false) {
      return (
        <ClientAcceptTerms />
      );
    } if (pendigContracts) {
      return (
        <CompleteContract pendigContracts={pendigContracts} setLoading={setLoading} />
      );
    }
    return (
      loading
        ? (
          <Grid container item xs={12} justify="center">
            <CircularProgress color="primary" />
          </Grid>
        )
        : <Route path={props.path} exact={props.exact} component={props.component} />
    );
  }
  return <Redirect to={paths.login} />;
};

export default ClientRoute;
