import client from '../client/index';

const login = (data) => client.post('/auth/login', data);

const refreshToken = () => client.get('/auth/refresh_token');

const removeRefreshToken = () => client.get('/auth/remove_refresh_token');

const forgotPassword = (data) => client.post('/auth/forgot_password', data);

const authService = {
  login,
  refreshToken,
  removeRefreshToken,
  forgotPassword,
};

export default authService;
