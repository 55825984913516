import * as yup from 'yup';

const carrierAcceptTermsSchema = (props) => {
  const { t } = props;
  const validation = yup.object().shape({
    terms: yup.bool().oneOf([true]).typeError(t('termsAndConditionsFraseRequired')),
  });

  return validation;
};

export default carrierAcceptTermsSchema;
