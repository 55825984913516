import {
  Box, Container, Grid, makeStyles,
} from '@material-ui/core';
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter, Redirect, Route, Switch, matchPath,
} from 'react-router-dom';
import NavBar from '../../components/components/NavBar';
import { useUser } from '../../store';
import AssociationRoute from './AssociationRoute';
import ClientRoute from './ClientRoute';
import PartnerRoute from './PartnerRoute';
import CarrierRoute from './CarrierRoute';
import paths from './paths';
import PrivateRoute from './PrivateRoute';

const Home = lazy(() => import('../../components/pages/Home/HomeView'));
const Login = lazy(() => import('../../components/pages/Login/LoginView'));
const ForgotPassword = lazy(() => import('../../components/pages/ForgotPassword/ForgotPassword'));
const Profile = lazy(() => import('../../components/pages/Profile/ProfileView'));
const NotFound = lazy(() => import('../../components/pages/NotFoundPage/NotFoundPage'));
const CreateContract = lazy(() => import('../../components/pages/CreateContract/CreateContract'));
const CreatePartner = lazy(() => import('../../components/pages/CreatePartner/CreatePartner'));
const SendWorkersDocumentation = lazy(() => import('../../components/pages/SendWorkersDocumentation/SendWorkersDocumentation'));
const CompleteContractCarrier = lazy(() => import('../../components/pages/CompleteContractCarrier/CompleteContractCarrier'));
const ChangePasswordEmail = lazy(() => import('../../components/pages/ChangePasswordEmail/ChangePasswordEmail'));

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      // if you want to set the md size value
      height: '90vh',
    },
    [theme.breakpoints.down('sm')]: {
      // if you want to set the md size value
      marginTop: '10px',
      // height: '85vh',

    },
  },
}));

const Routes = () => {
  const { user } = useUser();
  const whiteList = [paths.login, paths.forgotPassword, paths.changePasswordEmail];
  const classes = useStyles();

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path={paths.login} component={Login} exact />
          <Route path={paths.forgotPassword} component={ForgotPassword} exact />
          <Route path={paths.changePasswordEmail} component={ChangePasswordEmail} exact />
        </Switch>
        {
          user ? (
            <Box>
              <NavBar />
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.container}
              >
                <Container maxWidth="lg">
                  <Switch>
                    <PartnerRoute path={paths.createContract} component={CreateContract} exact />
                    <AssociationRoute path={paths.createPartner} component={CreatePartner} exact />
                    <ClientRoute
                      path={paths.sendWorkersDocumentation}
                      component={SendWorkersDocumentation}
                      exact
                    />
                    <CarrierRoute path={paths.completeContractCarrier} component={CompleteContractCarrier} exact />
                    <PrivateRoute path={paths.home} component={Home} exact />
                    <PrivateRoute path={paths.profile} component={Profile} exact />
                    <Route path={paths.notFound} component={NotFound} exact />
                  </Switch>
                </Container>
              </Grid>
            </Box>
          )
            : (!matchPath(window.location.pathname, {
              path: whiteList,
              exact: true,
            })
              && <Redirect to={paths.login} />
            )
        }
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
