import client from '../client/index';

const getContracts = (startIndex, stopIndex, filter) => client.get(`/carrier/contracts?start=${startIndex}&end=${stopIndex}&filter=${filter}`);

const getContractById = (contractId) => client.get(`/carrier/contract/${contractId}`);

const completeContract = (data) => client.post('/carrier/completeContract', data);

const updateCarrierProfile = (data) => client.put('/carrier/update_profile', data);

const carrierAcceptTerms = (data) => client.post('/carrier/accept_terms', data);

const carrierService = {
  getContracts,
  getContractById,
  completeContract,
  updateCarrierProfile,
  carrierAcceptTerms
};

export default carrierService;
