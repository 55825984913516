import { yupResolver } from '@hookform/resolvers';
import {
  Box,
  Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, makeStyles, Paper, Typography, useMediaQuery,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { base64StringToBlob } from 'blob-util';
import { clientService } from '../../../httpServices/services';
import completeContractValidations from '../../../validations/completeContractValidations';
import { useStylesPaper } from '../../../styles';
import getHost from '../../../helpers/getHost';
import SignatureCanvas from 'react-signature-canvas'
import FormInput from '../../controls/input';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '65px',
    },
  },
  checkBoxLabel: {
    paddingRight: '10px',
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
  title: {
    paddingBottom: '5px',
    fontSize: theme.typography.h2.fontSize,
  },
  send: {
    marginTop: '20px',
  },
  marginTop: {
    marginTop: '10px',
  },
  cover: {
    paddingTop: '10px',
  },
  files: {
    textAlign: 'start',
    color: theme.palette.primary.main,
  },
  filestoDownload: {
    textAlign: 'end',
  },
  paddingleft: {
    paddingLeft: '10px',
  },
  paddingTop0: {
    paddingTop: '0 !important',
  },
  partnerName: {
    paddingLeft: '20px',
  },
  bold: {
    fontWeight: 'bold',
  },
  anchor: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  label: {
    marginTop: '15px',
    fontSize: '0.75rem',
  },
  sign: {
    fontSize: '0.7rem',
    marginTop: '10px'
  },
  sigContainer: {
    border: '1px solid black'
  }
}));

const CompleteContract = (props) => {
  const sigPad = useRef(null)
  const BoxRef = useRef(null)
  const dialog = useRef(null)
  const { setLoading, pendigContracts } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const methods = useForm({
    resolver: yupResolver(completeContractValidations(useTranslation())),
  });

  
  const history = useHistory();
  
  // const [checkBoxValue, setCheckBoxValue] = useState(false);
  
  const { handleSubmit, errors } = methods;
  const [filesName, setFilesName] = useState([]);
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clicked, setClicked] = useState([]);
  const [contract] = useState(pendigContracts);

  const paperClasses = useStylesPaper();
  const classes = useStyles();

  // const handleCheckBoxChange = (e) => {
  //   setCheckBoxValue(e.target.checked);
  // };

  const handleRemove = (id) => {
    setFilesName((data) => (data.filter((fn) => fn.id !== id)));
    setFiles((data) => (data.filter((fn) => fn.id !== id)));
  };

  const handleOnchangeFile = (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      const newNames = [];
      const newFiles = [];
      Array.from(e.target.files).forEach((file) => {
        newNames.push({ id: (filesName.length + 1), name: file.name });
        newFiles.push({ id: (files.length + 1), file });
      });
      setFilesName((data) => ([...data, ...newNames]));
      setFiles((data) => ([...data, ...newFiles]));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    const formData = new FormData(document.getElementById('completeContract'));
    formData.delete('documents');
    files.forEach((file) => {
      formData.append('documents', file.file, file.file.name);
    });
    formData.append('licenceNumber', contract.licenceNumber);
    formData.append('signedBy', document.getElementById('signedBy').value);
    formData.append('signedByCardID', document.getElementById('signedByCardID').value);
    const sig = sigPad.current.toDataURL();
    const contentType = 'image/png';
    const sig2 = sig.split(",")[1]
    const blob = base64StringToBlob(sig2, contentType);
    formData.append('signature', blob);
    clientService.completeContract(formData)
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(('ok'), { variant: 'success' });
          setLoading(false);
          history.go(0);
        }
      })
      .catch(() => {
        enqueueSnackbar(t('server_error'), { variant: 'error' });
      });
  };
const handleclickedDocuments = (id) => {
  console.log(id);
  let isAlreadyClicked = false;
  for (let i of clicked) {
    if (i === id){
      isAlreadyClicked = true;
    }
  }
    if(!isAlreadyClicked){
      clicked.push(id)
      setClicked(clicked);
    }
    if((clicked?.length === contract.documents?.length + 1)){
      setDisabled(false)
    }
}

  const host = getHost();
  let url = "";
  let confirmationText = "";
  if (host === 'ANAPAT') {
    url = 'https://www.anapatapp.es/';
    confirmationText = t('downloadedFilesConfirmationCheckboxANAPAT')
  } else if (host === 'AECE') {
    url = 'https://www.aeceapp.es/';
    confirmationText = t('downloadedFilesConfirmationCheckboxAECE')
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Paper elevation={3} xs={12} className={paperClasses.root}>
        <FormProvider {...methods}>
          <form name="createContract" id="completeContract">
            <Grid container direction="column" alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h1">{t('pendingDocs')}</Typography>
              </Grid>
              <Grid item container direction="row" justify="flex-start" className={classes.paddingTop0}>
                <Typography variant="subtitle2" color="primary">{contract.client.name}</Typography>
                <Typography variant="subtitle2" color="primary" className={classes.paddingleft}>{contract.client.email}</Typography>
              </Grid>
              <Grid item container direction="column" className={classes.marginTop}>
                <Grid item container direction="row">
                  <Typography variant="body2" color="initial" className={classes.bold}>
                    {t('PartnerNameCompleteContract')}
                    :
                  </Typography>
                  <Typography variant="body1" color="initial" className={classes.paddingleft}>{contract.partner.name}</Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography variant="body2" color="initial" className={classes.bold}>
                    {t('cif')}
                    :
                  </Typography>
                  <Typography variant="body1" color="initial" className={classes.paddingleft}>{contract.partner.CIF}</Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography variant="body2" color="initial" className={classes.bold}>
                    {t('email')}
                    :
                  </Typography>
                  <Typography variant="body1" color="initial" className={classes.paddingleft}>{contract.partner.email}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row" className={classes.marginTop} alignItems="flex-start">
                <Typography variant="subtitle2" color="primary">
                  {t('startDateCompleteContract')}
                  :
                </Typography>
                <Typography variant="subtitle2" color="initial" className={classes.paddingleft}>{contract.rentStartDate}</Typography>
                <Typography variant="subtitle2" color="primary" className={classes.partnerName}>
                  {t('endDateCompleteContract')}
                  :
                </Typography>
                <Typography variant="subtitle2" color="initial" className={classes.paddingleft}>{contract.rentEndDate}</Typography>
              </Grid>
              
              {contract.documents?.length !== 0 && (
              <Grid container item direction="row" justify="space-between">
                <Grid item xs={6}>
                  <Typography variant="body2" color="initial" className={classes.bold}>
                    {t('attachmentFiles')}
                    : {''}
                  </Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={6} className={classes.filestoDownload}>
                  <div>
                    {contract.documents?.length !== 0 && contract.documents.map((fn, index) => {
                      contract.documents[index].clicked = false;
                      const file64 = fn?.data?.split('base64,')[1];
                      const blob = base64StringToBlob(file64);
                      return (
                        <Grid item xs={12} key={`fileToDownload${index}`}>
                          <Link variant="subtitle2" href={window.URL.createObjectURL(blob)} download={fn.name} className={classes.anchor} onClickCapture={() => {handleclickedDocuments(index)}}>{(fn.name).slice(0,20)}...{(fn.name).slice(fn.name.length-3,fn.name.length)}</Link>
                        </Grid>
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
              )}
              <Grid item container direction="row" alignItems="flex-start">
                <Typography variant="subtitle2" color="initial">
                  {t('linkManual1')}
                </Typography>
                <Typography variant="subtitle2" color="initial">
                  {t('linkManual2')}
                </Typography>
                <Link variant="subtitle2" href={url} className={classes.anchor} target="_blank" rel="noopener" onClick={() => handleclickedDocuments('urlid')}> {url}</Link>
                <Typography variant="subtitle2" color="initial">
                  {t('linkManual3')}
                </Typography>
              </Grid>
              <Grid container item direction="row" justify="space-between">
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    fullWidth={!matches}
                    onClick={() => document.getElementById('documents')?.click()}
                  >
                    {t('addDocs')}
                  </Button>
                  <input
                    accept="image/*,.pdf"
                    style={{ display: 'none' }}
                    id="documents"
                    name="documents"
                    type="file"
                    onInput={(e) => handleOnchangeFile(e)}
                  />
                </Grid>
              </Grid>
              <Grid item container justify="space-between" xs={12}>
                <Grid item xs={12} sm={9} className={classes.files}>
                  <div>
                    {filesName.length !== 0 && filesName.map((fn, key) => (
                      <Typography variant="body2" color="secondary" key={`filename${key}`}>
                        {(fn.name).slice(0,20)}...{(fn.name).slice(fn.name.length-3,fn.name.length)}
                        <IconButton aria-label="remove" size="small" onClick={() => handleRemove(fn.id)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Typography>
                    ))}
                  </div>
                </Grid>
              </Grid>
              <Grid item container justify="space-between" xs={12}>
                <Grid item container direction="column" xs={12} sm={12} justify="flex-end">
                  <Grid container direction="row" item justify="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth={!matches}
                      onClick={() => setOpen(true)}
                      className={classes.send}
                      disabled={disabled}
                      >
                      {t('continue')}
                    </Button>
                  </Grid>
                </Grid>
                </Grid>
              </Grid>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" ref={dialog}>
          <Box className={classes.box}>
            <DialogTitle id="form-dialog-title"><Typography className={classes.title} color="initial">{t('signatureTitle')}</Typography></DialogTitle>
            <DialogContent>
            <Grid item container direction="row" justify="space-evenly" xs={12} >
              <Grid item xs={5} sm={5}>
                <FormInput
                  name="signedBy"
                  id="signedBy"
                  label={t('signedBy')}
                  errorobj={errors}
                  required
                  />
                </Grid>
              <Grid item xs={5} sm={5}>
                <FormInput
                  name="signedByCardID"
                  id="signedByCardID"
                  label={t('signedByCardID')}
                  errorobj={errors}
                  required
                  />
                </Grid>
                </Grid>
              <Grid item container direction="row" justify="center" xs={12} sm={12}>
                  <Typography variant="subtitle2" color="textPrimary" className={classes.sign}>
                     {t('sign')}
                  </Typography>
              </Grid>
              <Grid item container direction="row" alignContent="center" justify="center" xs={12}>
                <Box className={classes.sigContainer} ref={BoxRef}>
                  <SignatureCanvas penColor='black' canvasProps={{className: 'sigPad', frameBorder: '1', width: '250%', height: '150%'}} ref={sigPad} />
                </Box>
                </Grid>
              <Typography color="textPrimary" className={classes.label} align='justify'>
                {confirmationText}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                {t('submit')}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
          </form>
        </FormProvider>
      </Paper>
    </Container>
  );
};

export default CompleteContract;
