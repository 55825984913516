const getHost = () => {
  let host;
  if (window.location.host === process.env.REACT_APP_AECE_URL) {
    host = 'AECE';
  } else if (window.location.host === process.env.REACT_APP_ANAPAT_URL) {
    host = 'ANAPAT';
  }
  return host;
};
export default getHost;
