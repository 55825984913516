import client from '../client/index';

const completeProfile = (data) => client.put('/partner/complete_profile', data);

const uploadPartnerImgProfile = (data) => client.put('/partner/upload_partner_img_profile', data, { headers: { 'Content-Type': 'multipart/form-data' } });

const aceptTerms = (data) => client.put('/partner/acept_terms', { terms: data });

const newContract = (data) => client.post('/partner/new_contract', data, { headers: { 'Content-Type': 'multipart/form-data' } });

const getTrazability = (contractId) => client.get(`/partner/trazability/${contractId}`);

const getContracts = (startIndex, stopIndex, filter) => client.get(`/partner/contracts?start=${startIndex}&end=${stopIndex}&filter=${filter}`);

const updatePartnerProfile = (data) => client.put('/partner/update_profile', data);

const resendContractEmail = (contractId) => client.get(`/partner/resend_contract_email/${contractId}`);

const updateContractClient = (data) => client.put('/partner/update_contract_client', data);

const deleteContract = (contractId) => client.delete(`/partner/delete_contract/${contractId}`);

const partnerService = {
  completeProfile,
  newContract,
  getTrazability,
  getContracts,
  aceptTerms,
  updatePartnerProfile,
  uploadPartnerImgProfile,
  resendContractEmail,
  updateContractClient,
  deleteContract
};

export default partnerService;
