import axios from 'axios';

const url = process.env.REACT_APP_API_URL || '';
axios.defaults.withCredentials = true;
const axiosClient = axios.create({
  headers: {
    'Access-Control-Allow-Origin': `${process.env.REACT_APP_AECE_URL_HTTP}`,
  },
  withCredentials: true,
  baseURL: url,
});

export default axiosClient;
