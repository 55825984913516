import { yupResolver } from '@hookform/resolvers';
import {
  Button, Checkbox, Container, Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useStylesPaper } from '../../../styles';
import { useUser } from '../../../store';
import carrierAcceptTermsSchema from '../../../validations/carrierAcceptTermsSchema';
import { carrierService } from '../../../httpServices/services';
import TermsAndConditionsWide from '../../components/TermsAndConditionsWide/TermsAndConditionsWide';
import getHost from '../../../helpers/getHost';

const fileTermsAece = require('../../../static/V2_Política_Protección_DatosAPP_AECE.pdf');
const fileTermsAnapat = require('../../../static/V2_Política_Protección_DatosAPP_anapat.pdf');

const fileLegalAece = require('../../../static/V2_Aviso_LegalAPP_AECE.pdf');
const fileLegalAnapat = require('../../../static/V2_Aviso_LegalAPP_anapat.pdf');

const CarrierAcceptTerms = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [checkBoxValue, setCheckBoxValue] = useState(false);

  const methods = useForm({
    resolver: yupResolver(carrierAcceptTermsSchema(useTranslation())),
  });

  const host = getHost();
  let fileLegal = null;
  let fileTerms = null;
  if (host === 'ANAPAT') {
    fileLegal = fileLegalAnapat;
    fileTerms = fileTermsAnapat;
  } else if (host === 'AECE') {
    fileLegal = fileLegalAece;
    fileTerms = fileTermsAece;
  }

  const useStyles = makeStyles((theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    colors: {
      primary: theme.palette.primary.main,
      secondary: theme.palette.secondary.main,
    },
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }));

  const onSubmit = () => {
    if (!checkBoxValue) {
      enqueueSnackbar(t('termsAndConditionsFraseRequired'), { variant: 'error' });
    } else {
      carrierService.carrierAcceptTerms({ terms: checkBoxValue })
        .then((res) => {
          if (res.status === 202) {
            enqueueSnackbar(t('submitedTerms'), { variant: 'success' });
            history.go(0);
          } else {
            enqueueSnackbar(t('server_error'), { variant: 'error' });
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            enqueueSnackbar(t('mustAcceptTerms'), { variant: 'error' });
          } else {
            enqueueSnackbar(t('server_error'), { variant: 'error' });
          }
        });
    }
  };

  const handleCheckBoxChange = (e) => {
    setCheckBoxValue(e.target.checked);
  };

  const { errors } = methods;

  const paperClasses = useStylesPaper();
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Paper elevation={3} xs={12} className={paperClasses.root}>
        <FormProvider {...methods}>
          <form>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12} className={classes.title}>
                <Typography variant="h1">
                  {t('hello')}
                  {' '}
                  {user.user.name}
                </Typography>
              </Grid>
              <Grid item container direction="row" justify="space-between" xs={12}>
                <Grid item container direction="row" alignContent="stretch" xs={12} md={9}>
                  <Checkbox
                    name="terms"
                    id="terms"
                    label={t('termsAndConditionsFraseCarriere')}
                    errorobj={errors}
                    value={checkBoxValue}
                    onChange={handleCheckBoxChange}
                    checked={checkBoxValue}
                    defaultValue={false}
                    initialValue={false}
                    ref={methods.register}
                  />
                  <Typography variant="body1" color="initial" style={{ paddingTop: '10px' }}>{t('termsAndConditionsFrase1')}</Typography>
                  <TermsAndConditionsWide anchorText={t('legalAdvise')} file={fileLegal} title={t('legalAdvise')} />
                  <Typography variant="body1" color="initial" style={{ paddingTop: '10px' }}>{t('termsAndConditionsFrase2')}</Typography>
                  <TermsAndConditionsWide anchorText={t('privatePolicy')} file={fileTerms} title={t('privatePolicy')} />
                </Grid>
                <Grid item xs={12} md={3} style={{ textAlign: 'end' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    className={classes.send}
                  >
                    {t('submit')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Paper>
    </Container>
  );
};

export default CarrierAcceptTerms;
