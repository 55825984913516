import * as yup from 'yup';

const completePartnerProfileSchema = (props) => {
  const { t } = props;
  return yup.object().shape({
    Address: yup.string().required(t('addressRequired')),
    comercial: yup.string().required(t('comercialRequired')),
  });
};

export default completePartnerProfileSchema;
