import client from '../client/index';

const changePassword = (data) => client.put('/user/change_password', data);

const firstChangePassword = (data) => client.put('/user/first_change_password', data);

const changePasswordToken = (data) => client.put('/user/change_password_token', data);

const updateProfile = (data) => client.put('/user/updateProfile', data);

const userService = {
  changePassword,
  changePasswordToken,
  updateProfile,
  firstChangePassword,
};

export default userService;
