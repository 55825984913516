const paths = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot_password',
  profile: '/profile',
  createContract: '/createContract',
  createPartner: '/createPartner',
  sendWorkersDocumentation: '/sendWorkersDocumentation/:id',
  sendWorkersDocumentationId: '/sendWorkersDocumentation/',
  changePasswordEmail: '/changePasswordEmail/:token',
  completeContractCarrier: '/completeContractCarrier/:contractId',
  completeContractCarrierId: '/completeContractCarrier/',
  notFound: '/*',
};

export default paths;
