import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormInput from "../../controls/input";
import { yupResolver } from "@hookform/resolvers";
// import ImageIcon from '@material-ui/icons/Image';

import { useUser } from "../../../store";
import { useSnackbar } from 'notistack';
import { partnerService } from "../../../httpServices/services";
// import Crop from "../../components/crop";
import completePartnerProfileSchema from "../../../validations/completePartnerProfileValidations";
import { Checkbox, Container, FormControl, FormControlLabel, makeStyles, Paper, Radio, RadioGroup, Typography } from "@material-ui/core";
import { useStylesPaper, useTextBox } from "../../../styles";
import TermsAndConditionsWide from "../../components/TermsAndConditionsWide/TermsAndConditionsWide";
import getHost from "../../../helpers/getHost";


const fileTermsAece = require("../../../static/V2_Política_Protección_DatosAPP_AECE.pdf")
const fileTermsAnapat = require("../../../static/V2_Política_Protección_DatosAPP_anapat.pdf")

const fileLegalAece = require("../../../static/V2_Aviso_LegalAPP_AECE.pdf")
const fileLegalAnapat = require("../../../static/V2_Aviso_LegalAPP_anapat.pdf")



const CompletePartnerProfile = () => {
  const { user } = useUser();
  // const [imgInput, setImgInput] = useState();
  // const [open, setOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const [radioValue, setRadioValue] = useState(true);
  const methods = useForm({
    resolver: yupResolver(completePartnerProfileSchema(useTranslation())),
  });
  const { handleSubmit, errors } = methods;
  const handleCheckBoxChange = (e) => {
    setCheckBoxValue(e.target.checked)
  }


  const host = getHost();
  var fileLegal = null;
  var fileTerms = null;
  var text = null;
  if (host === 'ANAPAT') {
    fileLegal = fileLegalAnapat;
    fileTerms = fileTermsAnapat;
    text = t('notTraceablilityANAPAT');
  } else if (host === 'AECE') {
    fileLegal = fileLegalAece;
    fileTerms = fileTermsAece;
    text = t('notTraceablilityAECE');
  }

  const useStyles = makeStyles((theme) => ({
    checkBoxLabel: {
      paddingRight: '10px',
    },
    error: {
      color: 'red',
      textAlign: 'center'
    },
    terms: {
      paddingLeft: '0.3rem',
    },
    icon: {
      fontSize: 100
    },
    title: {
      paddingBottom: '30px !important',
    },
    send: {
      marginTop: '20px'
    },
    cover: {
      paddingTop: '10px'
    }
  }));

  const handleOnchangeRadio = (e) => {
    if(e.target.value === 'no'){
      setRadioValue(false)
    }else {
      setRadioValue(true)
    }
  }
  

  const onSubmit = (data) => {
    if (!checkBoxValue) {
      enqueueSnackbar(t('termsAndConditionsFraseRequired'), { variant: 'error' });
    } else {
      data.termsAndConditions = true;
      data.traceability = radioValue;

      //upload partner data profile
      partnerService.completeProfile(data)
        .then(res => {
          if (res.data !== undefined) {
            enqueueSnackbar(t('profile_completed'), { variant: 'success' });
            history.go(0);
          } else {
            enqueueSnackbar(t('server_error'), { variant: 'error' });
          }
        })
        .catch(err => {
          enqueueSnackbar(t('server_error'), { variant: 'error' });
        })


      // if(imgInput !== undefined){
      //   formData.append("partnerImgPath", imgInput, "partnerImgPath.jpg");

      // //upload partner img profile
      // partnerService.uploadPartnerImgProfile(formData)
      //   .then(res => {
      //     if (res.data !== undefined) {


      //     } else {
      //       enqueueSnackbar(t('server_error'), { variant: 'error' });
      //     }
      //   })
      //   .catch(err => {
      //     enqueueSnackbar(t('server_error'), { variant: 'error' });
      //   })

      // }else {
      //   //upload partner data profile
      //   partnerService.completeProfile(data)
      //   .then(res => {
      //     if (res.data !== undefined) {
      //       enqueueSnackbar(t('profile_completed'), { variant: 'success' });
      //       history.go(0);
      //     } else {
      //       enqueueSnackbar(t('server_error'), { variant: 'error' });
      //     }
      //   })
      //   .catch(err => {
      //     enqueueSnackbar(t('server_error'), { variant: 'error' });
      //   })
      // }
    }
  };

  const paperClasses = useStylesPaper();
  const textBoxClasses = useTextBox();
  const classes = useStyles();

  return (
    <Container maxWidth="md" >
      <Paper elevation={3} xs={12} className={paperClasses.root}>
        <FormProvider {...methods}>
          <form name="form" id="form">
            <Grid container direction="column" alignItems="center" spacing={1}>
              <Grid item xs={12} className={classes.title}>
                <Typography variant="h1">{t('hello')} {user.user.name}, {t('completeProfile')}</Typography>
              </Grid>
              {/* <Grid item xs={12}>
                {imgInput ?
                  <img
                    alt={t('cropedImg')}
                    className={classes.cover}
                    src={window.URL.createObjectURL(imgInput)}
                  /> :
                  <ImageIcon className={classes.icon} />
                }
                <Crop setImgInput={setImgInput} open={open} setOpen={setOpen} />
              </Grid> */}
              {/* <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  size="small"
                >
                  {t('upload_image')}
                </Button>
              </Grid> */}
              <Grid item className={textBoxClasses.root}>
                <FormInput
                  name="comercial"
                  label={t('comercial')}
                  required={true}
                  errorobj={errors}
                  defaultValue={user.user.comercial || ''}
                />
              </Grid>
              <Grid item className={textBoxClasses.root}>
                <FormInput
                  name="Address"
                  label={t('address')}
                  required={true}
                  errorobj={errors}
                  defaultValue={user.user.Address || ''}
                />
              </Grid>
              <Grid item xs={12} >
                <Grid container item direction="row" xs={12} justify="center" alignItems="center" spacing={0}>
                  <Grid item className={classes.checkBoxLabel}>
                    {t('traceability')}
                  </Grid>
                  <Grid item>
                    <FormControl component="fieldset">
                        <RadioGroup row name="traceability" id="traceability" defaultValue={'yes'} onChange={(e) => handleOnchangeRadio(e)}>
                          <FormControlLabel
                            key='no'
                            value='no'
                            control={<Radio />}
                            label={t('no')}
                          />
                          <FormControlLabel
                            key='yes'
                            value='yes'
                            control={<Radio />}
                            label={t('yes')}
                          />
                        </RadioGroup>
                    </FormControl>
                  </Grid>
                  {
                     !radioValue && 
                    <Typography variant="body2" color="initial" align="justify">{text}</Typography>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container item direction="row" xs={12} justify="center" alignItems="center">
                  <Grid item>
                    <Checkbox
                      name="termsAndConditions"
                      id="termsAndConditions"
                      label={t('termsAndConditionsFraseAccept')}
                      errorobj={errors}
                      value={checkBoxValue}
                      onChange={handleCheckBoxChange}
                      checked={checkBoxValue}
                      defaultValue={false}
                      ref={methods.register}
                    />
                    {t('termsAndConditionsFrase1')}
                  </Grid>
                  <Grid item className={classes.terms}>
                    <TermsAndConditionsWide anchorText={t('legalAdvise')} file={fileLegal} title={t('legalAdvise')} />
                    {t('termsAndConditionsFrase2')}
                  </Grid>
                  <Grid item className={classes.terms}>
                    <TermsAndConditionsWide anchorText={t('privatePolicy')} file={fileTerms} title={t('privatePolicy')} />
                  </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" className={classes.error}>
                  {errors && errors['termsAndConditions']?.message}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  className={classes.send}
                >
                  {t('submit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Paper>
    </Container>
  );
}

export default CompletePartnerProfile;