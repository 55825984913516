import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IconButton, Typography, Tooltip } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import authService from '../../httpServices/services/authService';
import { useUser } from '../../store';
import paths from '../../config/router/paths';
import LogoANAPAT from '../../static/logos/logo_anapat.png';
import LogoAECE from '../../static/logos/Logo-AECE-blanco.png';
import getHost from '../../helpers/getHost';
import { AECEColors, ANAPATColors } from '../../styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      // if you want to set the md size value
      display: 'none',
    },
  },
}));

const NavBar = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { setUser } = useUser();

  const logOut = () => {
    authService.removeRefreshToken()
      .then(() => {
        setUser(undefined);
      })
      .catch(() => {
      });
  };

  const host = getHost();
  let logo = null;
  let navBarText = null;
  let color = null;

  if (host === 'ANAPAT') {
    logo = LogoANAPAT;
    navBarText = t('aplicationNameANAPAT');
    color = ANAPATColors.secondary.main.hex;
  } else if (host === 'AECE') {
    logo = LogoAECE;
    navBarText = t('aplicationNameAECE');
    color = AECEColors.primary.main.hex;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{backgroundColor: color}}>
        <Toolbar>
          <Link to={paths.home}>
            <img
              alt="logo"
              style={{ width: '125px', marginTop: '2px', marginBottom: '2px' }}
              src={logo}
            />
          </Link>
          <Typography variant="h1" className={classes.title} style={{ paddingLeft: '15px', paddingTop: '10px', fontSize: '17px' }}>
            {navBarText}
          </Typography>

          <Tooltip title={t('profile')}>
            <IconButton color="inherit" href="/profile" to={paths.profile}><AccountCircleIcon style={{ fontSize: '30px' }} /></IconButton>
          </Tooltip>
          <Tooltip title={t('logOut')}>
            <IconButton color="inherit" onClick={logOut}><PowerSettingsNewIcon style={{ fontSize: '30px' }} /></IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
