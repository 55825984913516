import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { AECEColors } from './index';

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
}

const breakpoints = createBreakpoints({ ...customBreakpointValues })

export const themeAECE = createMuiTheme({
  breakpoints: {
    ...customBreakpointValues,
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    primary: {
      light: AECEColors.primary.light.hex,
      main: AECEColors.primary.main.hex,
      dark: AECEColors.primary.dark.hex,
      contrastText: AECEColors.primary.text.hex,
    },
    secondary: {
      light: AECEColors.secondary.light.hex,
      main: AECEColors.secondary.main.hex,
      dark: AECEColors.secondary.dark.hex,
      contrastText: AECEColors.secondary.text.hex,
    },
    error: {
      light: AECEColors.error.light.hex,
      main: AECEColors.error.main.hex,
      dark: AECEColors.error.dark.hex,
      contrastText: AECEColors.error.contrastText.hex,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  overrides: {
    MuiButton: {
      outlinedSecondary: {
        '&:hover': {
          backgroundColor: AECEColors.secondary.main.rgbaDegradation,
        },
      },
    },
    MuiPaper: {
      root: {
        '&.MuiPaper-root': {
          [breakpoints.down('sm')]: {
            // if you want to set the md size value
            padding: '0px !important',
            backgroundColor: 'white',
            boxShadow: 'none'
          },
        },
      }
    },
  },
  typography: {
    fontFamily: 'Open Sans,Arial,sans-serif',
    h1: {
      fontSize: '1.8rem',
    },
    h2: {
      fontSize: '1.6rem',
    },
    h3: {
      fontSize: '1.4rem',
    },
    h4: {
      fontSize: '1.2rem',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '0.8rem',
    },
  },
});
export default themeAECE;
